<template>
    <div class="wrapper flex flex-column">
        <Header @search="handleFilterEvent" :navList="navList" navCurrentId="2" :logo="logoUrl"></Header>
        <div class="flex-item">
            <div class="container filter-wrapper">
                <filter-tab v-if="subjectList.length" :selected="subjectIndex" title="学科" :list="subjectList" :isShowMore="true" @clickItem="handleFilterEvent('subjectIndex',$event)"></filter-tab>
                <filter-tab v-if="categoryList.length" title="类别" :selected="categoryIndex" :list="categoryList" @clickItem="handleFilterEvent('categoryIndex',$event)"></filter-tab>
            </div>
             <!-- 筛选条件 -->
            <div v-if="subjectIndex != -1 || $route.query.search_name || categoryIndex != -1" class="filter-condition mt20 active">
                <div class="container flex y-center x-left">
                    <div class="search-text">搜索条件：</div>
                    <a-breadcrumb class="search-breadcrumb">
                        <a-breadcrumb-item v-if="$route.query.search_name">{{ $route.query.search_name }}</a-breadcrumb-item>
                        <a-breadcrumb-item v-if="subjectIndex != -1">{{ subjectList[subjectIndex].operate_name }}</a-breadcrumb-item>
                        <a-breadcrumb-item v-if="categoryIndex != -1">{{ categoryList[categoryIndex].name }}</a-breadcrumb-item>
                    </a-breadcrumb>
                    <a-button class="clear-btn" @click="handleClearEvent"  style="margin-left:20px;" size="small">清除</a-button>
                </div>
            </div>
            <div class="live-content container flex mt20">
                <div class="live-list flex-item">
                    <a-spin style="min-height:300px" :spinning="loading" tip="Loading...">
                        <div class="flex x-left flex-wrap">
                            <!-- <div :class="[,{'ml20' : index%3 != 0,'mt20' : index > 2}]" v-for="(item,index) in videoList" :key="index"> -->
                                <course-item width="230px" height="130px" :data-list="videoList"></course-item>
                                <!-- <vertical-media-item gap="0" width="230px" height="130px" :item="item" @clickEvent="videoJumpDetail(item.id)"></vertical-media-item> -->
                            <!-- </div> -->
                        </div>
                        <a-empty v-if="!videoList.length && !loading" />
                    </a-spin>
                    <div class="t-c mt30" v-if="videoTotal > 0">
                        <a-pagination :total="videoTotal" v-model:current="params.page" :page-size="params.PageSize" show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                    </div>
                </div>
                <div class="live-right">
                    <!-- 申请入驻讲师 -->
                    <!-- <img class="apply-teacher" src="~@/assets/images/apply-teacher.png" alt=""> -->
                    <!--  class="mt50" -->
                    <div>
                        <layout-right :config="layoutRightConfig"></layout-right>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import commonTitle from '@/components/common-title';
import Header from '@/components/layout/layout-header';
import layoutRight from '@/components/layout/layout-right';
import Footer from '@/components/layout/layout-footer';
import filterTab from '@/components/filter-tab';
import textItem from '@/components/text-item';
import acrossMediaItem from '@/components/across-media-item.vue';
import videoPlaybackItem from '@/components/video-playback-item.vue';
import verticalMediaItem from '@/components/vertical-media-item.vue';
import courseItem from '@/components/courser-list-item';
import { videoLogo, mediaNavList } from '@/config/const'
import { jsonFormat } from '@/utils/jsonFormat';
import { videoJumpDetail } from '@/utils/jumpPageMethods';
import storage from 'store';
import { mapState } from 'vuex';

let layoutRightConfig = {
    news: {
        show: true, // 是否显示
        methods: 'post',
        title: '新闻资讯', // 标题
        data: {
            page: 1,
            limit: 5,
            type: 2,
        },   // 参数条件
    },
    meeting: {
        show: true, // 是否显示
        title: '推荐会议', // 标题
        methods: 'get',
        data: {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            page: 1,
            pageSize: 3,
            is_now_year_meeting: 1,
        },   // 参数条件
    },
    // 直播
    live: {
        show: true, // 是否显示
        methods: 'get',
        title: '推荐直播', // 标题
        data: {
            page: 1,
            limit: 3
        },   // 参数条件
    },

}

export default {
    name: "VideoList",
    components: {
        commonTitle,
        Header,
        layoutRight,
        filterTab,
        textItem,
        acrossMediaItem,
        videoPlaybackItem,
        verticalMediaItem,
        courseItem,
        Footer
    },
    data() {
        return {
            logoUrl: videoLogo,
            navList: mediaNavList,//顶部菜单
            // 学科筛选
            subjectIndex: -1,
            subjectList: [],
            categoryIndex: -1,
            categoryList: [],
            videoList: [],
            videoTotal: 0,
            params: {
                page: 1,
                PageSize: 21,
                class:this.$route.query.category_id,
                no_count:false,
            },
            layoutRightConfig: {},
            loading: true,
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        // 清除事件
        handleClearEvent() {
            this.subjectIndex = -1;
            this.categoryIndex = -1;
            if (this.$route.query.search_name) {
                this.$router.replace({
                    path: '/video-list'
                })
            }
            this.handleFilterEvent();
        },
        videoJumpDetail,
        getSubject() {
            return this.$store.dispatch('getSubjectList')
        },
        getVideoLike() {
            let data = { ...this.params };
            let { search_name } = this.$route.query;
            search_name && (data['name'] = search_name);
            this.loading = true;
            this.request.get('GetVideoListNew', data).then(res => {
                // 视频列表
                let videoList = (res.data || {}).list || [];
                let videoListField = {
                    id: 'id',
                    img: 'default_pic_url',
                    title: 'title',
                    name: 'author',
                    org: 'org_cnname',
                }
                this.loading = false;
                this.videoTotal = parseInt(res.data.count);
                this.videoList = jsonFormat(videoList, videoListField)
                this.$forceUpdate()
            }).catch(err => {
                this.loading = false;
            })
        },
        // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0,0);
            this.params.page = page
            this.getVideoLike()
        },
        GetResourceCategoryList() {
            return this.request.get('GetResourceCategoryList')
        },
        // 学科切换事件
        handleFilterEvent(key, e) {
            key && (this[key] = e);
            this.params.page = 1

            // 用户url学科或者已选学科
            let subject_ids = this.$route.query.subject_id;
            let operate_subject = subject_ids;
            
            // 切换后的学科
            if(this.subjectIndex != -1){
                subject_ids = this.subjectList[this.subjectIndex].subject_ids;
                operate_subject = this.subjectList[this.subjectIndex].id;
                this.params.subject_ids = subject_ids
            }else {
                this.params.subject_ids && (delete this.params.subject_ids);
            }

            // 类别
            if (this.categoryIndex != -1) {
                this.params.class = this.categoryList[this.categoryIndex].id
            } else {
                this.params.class && (delete this.params.class);
            }

             // 右侧数据调整  更新右侧数据
            layoutRightConfig.news.data.operate_id = operate_subject;
            layoutRightConfig.meeting.data.subject_ids = subject_ids;
            layoutRightConfig.live.data.subject_ids = subject_ids;

            this.layoutRightConfig = JSON.parse(JSON.stringify(layoutRightConfig));

            
            this.getVideoLike();
        },
        // 视频详情
        //videoDetail(item) {
            // this.$router.push({
            //     path: '/video-list-detail',
            //     query: {
            //         id: item.id
            //     }
            // })
        //},
        pageInit() {
            // alert('当前页接口需要调整')

            // url筛选学科
            let { subject_id } = this.$route.query;
            // 用户自己关注的学科
            subject_id = subject_id || this.userInfo.operate_subject;

            let requestArr = [
                this.GetResourceCategoryList(),
                this.getSubject()
            ]
            Promise.all(requestArr).then(res => {
                // 视频资源分类
                let resourceList = res[0].data
                let categoryList = [];
                let index = 0;
                for (let k in resourceList) {
                    
                    // 数据回显
                    if (this.params.class == k) {
                        this.categoryIndex = index;
                    }
                    categoryList.push({
                        id: k,
                        name: resourceList[k],
                        category_id: k
                    })
                    ++index;
                }
                this.categoryList = categoryList
              
                // 学科列表
                let subjectList = [];
                res[1].data.forEach((item,index) => {
                    item.name = item.operate_name;
                    if(subject_id == item.id){
                        this.subjectIndex = index;
                    }
                    subjectList.push(item);
                })
                this.subjectList = subjectList;

                this.handleFilterEvent();
            })
        },
    },
    // 缓存页面二次进入的钩子函数  动态参数更新时开启
    // activated() {
    //         conosle.log(111);
    //     this.getParams();
    // },
    created() {
        // this.getParams();
        this.pageInit();
    }
}
</script>

<style lang="less" scoped>
.wrapper {
    min-height: 100vh;
    background: url("~@/assets/images/live-nav-top-bg.png") repeat-x;
    background-size: 1920 auto;
}

.filter-wrapper {
    background: #ffffff;
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
}

.live-content {
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
}

.live-list {
    margin-right: 30px;
    .mh {
        min-height: 500px;
    }
}

// 直播推荐
.recommend-first {
    width: 50%;
    cursor: pointer;
    .rf-image {
        width: 100%;
        height: 208px;
    }
}

.recommend-list {
    width: calc(50% - 20px);
    margin-left: 20px;

    .recommend-item {
        cursor: pointer;
        padding-bottom: 7px;
        margin-top: 7px;
        display: block;
        border-bottom: 1px dashed #d8d8d8;

        &:last-child {
            padding-bottom: 0;
            border: 0;
        }

        &:nth-of-type(1) {
            margin-top: 0;
        }
    }
}

.live-right {
    width: 400px;

    .paper-writing {
        height: 24px;
        display: flex;
        cursor: pointer;
    }

    .apply-teacher {
        width: 100%;
        height: 92px;
    }
}
@media screen and (max-width: 768px) {
    .filter-wrapper,
    .live-list,
    .live-right {
        border-radius: 0;
        width: 100%;
    }
    .wrapper {
        .filter-condition.active{
            margin-top:10px;
            height:40px;
            line-height:40px;
            .container{
                padding:0 10px;
                display: flex;
            }
        }
    }
    .filter-wrapper{
        margin-bottom:10px;
        padding:0;
        position: sticky;
        z-index:9;
        top:50px;
        background-color: #fff;
        /deep/.filter-box>.mt5{
            padding-top:10px;
            margin-top:0;
        }
    }
    .live-right {
        padding-top: 20px;
    }
    .live-list{
        /deep/.ant-spin-nested-loading{
            min-height: 0!important;
        }
    }
    .live-content {
        border-radius: 0;
        display: block;
        width: 100%;
        padding:10px;
        margin-top:0;
    }
}
</style>