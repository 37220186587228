<template>
    <div class="component-box">
        <div class="course-block flex x-left y-center flex-wrap">
            <div :style="{width:width}" :class="['course-item', { 'mr0' : (index + 1) % 3 == 0 }]" v-for="(item,index) in dataList" :key="index">
                <div :style="{height:height}" class="publicity-picture">
                    <img src="~@/assets/images/adv-banner.png" alt="">
                    <div class="section-count">
                        34节
                    </div>
                </div>
                <div class="course-info">
                    <div class="title row-2 strong">2021全国神经肿瘤学术大会中华医学会</div>
                    <div class="source flex x-left mt10">
                        <img class="img" src="~@/assets/images/adv-banner.png" alt="">
                        <div class="flex-column ml10 x-center">
                            <span class="strong fs14 color333">杨进刚</span>
                            <span class="fs12 color999">广东省医学会</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "courser-list-item",
        props : {
            dataList : {
                type : Array,
                default : () => []
            },
            lineCount : {
                type : Number,
                default : 3
            },//几个一行，最后一个需要设置marginRight 0
            width:{
                type:String,
                default:'243px'
            },
            height:{
                type:String,
                default:'137px'
            },
        }
    }
</script>

<style scoped lang="less">
    .component-box{
        .course-block{
            .course-item{
                width: 243px;
                text-align: left;
                cursor : pointer;
                border-radius: 6px;
                overflow: hidden;
                margin-right: 20px;
                margin-bottom: 20px;
                &.mr0{
                    margin-right: 0;
                }
                .publicity-picture{
                    width: 100%;
                    height: 137px;
                    position: relative;
                    overflow: hidden;
                    img{
                        width: 100%;
                        height: 100%;
                        display: block;
                        transition: all .6s;
                    }
                    &:hover img{
                        transform: scale(1.2);
                    }
                    .section-count{
                        background: url("~@/assets/images/course-section-bg.png") no-repeat;
                        background-size: contain;
                        width: 48px;
                        height: 26px;
                        position: absolute;
                        bottom: 2px;
                        left: 3px;
                        color: #FFFFFF;
                        text-align: center;
                        font-size: 12px;
                        line-height: 26px;
                    }
                }
                .course-info{
                    background: #F9F9F9;
                    height: 122px;
                    padding: 10px;
                    box-sizing: border-box;
                    .title{
                        color: @title;
                        height: 48px;
                    }
                    .source{
                        color: #666666;
                        font-size: 14px;
                        height: 44px;
                        .img{
                            width: 44px;
                            height: 44px;
                            border-radius: 50%;
                        }
                    }
                    .other{
                        .study-num{
                            color: @desc;
                            font-size: 12px;
                        }
                        .money{
                            margin-left: auto;
                            font-size: 18px;
                            color: #FFA45D;
                            position: relative;
                            top: 2px;
                            span{
                                font-size: 12px;
                                position: relative;
                                top: 2px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
